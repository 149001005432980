import {
    buttonAddHelpVideo,
    buttonAddContentFn,
    buttonAddPackageFn,
} from "@/libs/buttonEvents.js"

document.addEventListener("DOMContentLoaded", () => {
    setTimeout(() => {
        buttonAddHelpVideo()
        buttonAddContentFn()
        buttonAddPackageFn()
        window.buttonAddContentFn = buttonAddContentFn
        window.buttonAddPackageFn = buttonAddPackageFn
    }, 1500)
    const cardWithVideo = document.querySelectorAll(".card-with-video")

    cardWithVideo.forEach((card) => {
        card.addEventListener(
            "click",
            () => {
                const videoUrl = card.dataset?.videoUrl
                if (videoUrl) {
                    window.eventBus.$emit("exampleVideo", videoUrl)
                }
            },
            { passive: true },
        )
    })

    // Create a little arrow if cards are visible in mobile
    if ("IntersectionObserver" in window) {
        const cards = document.querySelectorAll(".card")
        const observer = new IntersectionObserver(
            (entries) => {
                entries.forEach((entry) => {
                    if (entry.intersectionRatio > 0.5) {
                        entry.target.classList.add("in-view")
                    } else if (entry.intersectionRatio < 0.2) {
                        entry.target.classList.remove("in-view")
                    }
                })
            },
            { threshold: [0.2, 0.5] },
        )

        cards.forEach((card) => observer.observe(card))
    }
})
